import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import LanguageDropDown from "../Vodcast/components/Language";
import { ThreeCircles } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import swal from "sweetalert";
import useValidateWithoutLogin from "../components/Hooks/useValidateWithoutLogin";
import { CropEasy } from "../components/crop/utils/CropEasy";
import eye from "../images/eye-solid.svg";

function Register1Professional() {
  useValidateWithoutLogin();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [isValid1, setIsValid1] = useState(true);
  const [btnDisable, setBtnDisable] = useState(false);
  const [openCrop, setOpenCrop] = useState(false);
  const [photoURL, setPhotoURL] = useState("");
  const [Pass1, setPass1] = useState("");
  const [Pass2, setPass2] = useState("");
  const [file, setFile] = useState([]);
  const [checkBox, setCheckBox] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const languages = [
    { id: 1, name: "Kannada" },
    { id: 2, name: "English" },
    { id: 3, name: "Hindi" },
    { id: 4, name: "Bengali" },
    { id: 5, name: "Marathi" },
    { id: 6, name: "Telugu" },
    { id: 7, name: "Tamil" },
    { id: 8, name: "Gujarati" },
    { id: 9, name: "Urdu" },
    { id: 10, name: "Bhojpuri" },
    { id: 11, name: "Malayalam" },
    { id: 12, name: "Odia" },
    { id: 13, name: "Punjabi" },
    { id: 14, name: "Rajasthani" },
    { id: 15, name: "Chhattisgarhi" },
    { id: 16, name: "Assamese" },
    { id: 17, name: "Maithili" },
    { id: 18, name: "Magahi" },
    { id: 19, name: "Haryanvi" },
    { id: 20, name: "Khortha/Khotta" },
    { id: 21, name: "Marwari" },
    { id: 22, name: "Santali" },
    { id: 23, name: "Kashmiri" },
    { id: 24, name: "Bundeli/Bundel Khandi" },
    { id: 25, name: "Malvi" },
    { id: 26, name: "Sadan/Sadri" },
    { id: 27, name: "Mewari" },
    { id: 28, name: "Awadhi" },
    { id: 29, name: "Wagdi" },
    { id: 30, name: "Lamani/Lambadi" },
    { id: 31, name: "Pahari" },
    { id: 32, name: "Bhili/Bhilodi" },
    { id: 33, name: "Hara/Harauti" },
    { id: 34, name: "Nepali" },
    { id: 35, name: "Gondi" },
    { id: 36, name: "Bagheli/Baghel Khandi" },
    { id: 37, name: "Sambalpuri" },
    { id: 38, name: "Dogri" },
    { id: 39, name: "Garhwali" },
    { id: 40, name: "Nimadi" },
    { id: 41, name: "Surjapuri" },
    { id: 42, name: "Konkani" },
    { id: 43, name: "Kumauni" },
    { id: 44, name: "Kurukh/Oraon" },
    { id: 45, name: "Tulu" },
    { id: 46, name: "Manipuri" },
    { id: 47, name: "Surgujia" },
    { id: 48, name: "Sindhi" },
    { id: 49, name: "Bagri" },
    { id: 50, name: "Ahirani" },
    { id: 51, name: "Banjari" },
    { id: 52, name: "Brajbhasha" },
    { id: 53, name: "Dhundhari" },
    { id: 54, name: "Bodo/Boro" },
    { id: 55, name: "Ho" },
    { id: 56, name: "Gojri/Gujjari/Gujar" },
    { id: 57, name: "Mundari" },
    { id: 58, name: "Garo" },
    { id: 59, name: "Kangri" },
    { id: 60, name: "Khasi" },
    { id: 61, name: "Kachchhi" },
  ];

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    console.log("Selected Language:", language);
  };

  const validateEmail = (email) => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return emailRegex.test(email);
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
    setIsValid(validateEmail(event.target.value));
  };

  const validatePass = (pass) => {
    const passRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return passRegex.test(pass);
  };

  const handleChangePass = (event) => {
    setPass1(event.target.value);
    setIsValid1(validatePass(event.target.value));
  };

  const [eyeV, setEyeV] = useState("password");
  function visible() {
    if (eyeV == "password") setEyeV("text");
    else setEyeV("password");
  }
  const [eyeV2, setEyeV2] = useState("password");
  function visible2() {
    if (eyeV2 == "password") setEyeV2("text");
    else setEyeV2("password");
  }

  async function Register() {
    if (email === "" || name === "")
      return toast.warn("Please fill all fields!");

    if (isValid == false) return toast.warn("Choose a Correct Email");

    if (Pass1.length < 8)
      return toast.warn("Please enter minimum 8 characters");

    if (isValid1 === false)
      return toast.warn("Please enter a special character !");

    if (Pass1 != Pass2) return toast.warn("write correct Password");

    if (checkBox == false)
      return toast.warn("Please Accept Terms and Conditions");

    setBtnDisable(true);
    let formData = new FormData();
    formData.append("image", file);
    formData.append("email", email);
    formData.append("password", Pass1);
    formData.append("full_name", name);
    formData.append("user_type", "professional");

    let response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/auth/register`,
      {
        method: "POST",
        body: formData,
      }
    );
    let result = await response.json();
    toast.success(result.message);

    if (result.status == true) navigate("/Professional-login");
    else setBtnDisable(false);
  }

  // const [preview, setPreview] = useState("");

  // const handleFileInputChange = (e) => {
  // 	const file = e.target.files[0];
  // 	if (file) {
  // 		setFile(file);
  // 		// setImage(URL.createObjectURL(file));
  // 		setPhotoURL(URL.createObjectURL(file));
  // 		setOpenCrop(true);
  // 	}
  // 		const reader = new FileReader();

  // 		reader.onloadend = () => {
  // 			setPreview(reader.result);
  // 		};

  // 	if (file) {
  // 		reader.readAsDataURL(file);
  // 	} else {
  // 		setPreview("");
  // 	}
  // 	e.target.value = null;
  // };

  return (
    <div className="professional-login">
      {openCrop && (
        <CropEasy {...{ photoURL, setOpenCrop, setPhotoURL, setFile }} />
      )}
      <div className="left">
        <div className="leftHeading">
          Teach your audiance what they want to learn
        </div>
        <br />
        <br />
        <br />
        <br />
        <div className="dots">
          <div className="dot-purple"></div>
          <div className="dot-gray"></div>
          <div className="dot-gray"></div>
        </div>
      </div>

      <div className="Registercard card professional-card">
        <div className="card-body">
          <h1 className="professional-card-title">REGISTER</h1>
          <br />
          {/* <div>
						{photoURL ? (
							<img src={photoURL} alt="Preview" className="round-img" style={{ height: "50px", width: "50px" }} />
						) : (
							<img src={image} alt="Preview" className="round-img" style={{ height: "50px", width: "50px" }} />
						)}
						<input type="file" id="image-input" onChange={handleFileInputChange} />
					</div> */}
          <input
            type="text"
            placeholder="Name"
            onChange={(e) => setName(e.target.value)}
          />
          <input type="email" placeholder="Email" onChange={handleChange} />
          <LanguageDropDown
            options={languages}
            placeholder="Choose a language"
            onChange={handleLanguageChange}
            value={selectedLanguage}
          />
          <input
            className="eye-pass"
            type={eyeV}
            placeholder="Password"
            onChange={handleChangePass}
          />
          <img
            src={eye}
            className="eye-register"
            onClick={visible}
            style={{ cursor: "pointer" }}
            alt=""
          />
          <input
            className="eye-pass"
            type={eyeV2}
            placeholder="Password"
            onChange={(e) => setPass2(e.target.value)}
          />
          <img
            src={eye}
            className="eye2-register"
            onClick={visible2}
            style={{ cursor: "pointer" }}
            alt=""
          />{" "}
          <div className="display-flex">
            <div>
              <input
                type="checkbox"
                onChange={(event) => setCheckBox(event.target.checked)}
                style={{
                  height: "15px",
                  marginTop: "10px",
                  marginLeft: "15px",
                }}
              />
            </div>
            <div style={{ marginLeft: "15px" }}>
              <p className="card-text">
                I Agree to{" "}
                <Link to="/professional-privacy">terms & conditions </Link>and{" "}
                <Link to="/professional-privacy">privacy policy</Link>
              </p>
            </div>
          </div>
          <button
            className="loginWithEmail"
            disabled={btnDisable}
            onClick={Register}
          >
            {btnDisable ? "" : "Continue"}
            <ThreeCircles
              height="30"
              width="100%"
              color="#4fa94d"
              wrapperStyle={{}}
              wrapperClass=""
              visible={btnDisable}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </button>
          <p className="card-text">
            I already have an account{" "}
            <Link to="/Professional-login">Login</Link>
          </p>
        </div>
      </div>
    </div>
  );
}
export default Register1Professional;
