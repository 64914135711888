import * as React from "react";
import { useState} from "react";
import LanguageDropDown from "../components/Language";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import constants from "../../configs/constants";
import {
  Alert,
  AspectRatio,
  Box,
  CircularProgress,
  Container,
  DialogContent,
  Grid,
  IconButton,
  Input,
  ListItem,
  ModalDialog,
  Option,
  Select,
  Stack,
  Textarea,
} from "@mui/joy";
import {
  RiArrowLeftLine,
  RiCloseLine,
  RiCrossLine,
  RiDragDropLine,
  RiDropLine,
  RiDropboxLine,
  // RiFileVideoLine,
  RiVideoLine,
} from "react-icons/ri";
import { FaRegFileVideo } from "react-icons/fa6";
import { byteConverter } from "../../utils/byteConverter";
import VideoEditor from "../components/VideoEditor";
import UploadingView from "./UploadingView";
import SuccessView from "./SuccessView";
import { getVideoCover } from "../../utils/getVideoCover";
import { blobToFile } from "../../utils/blobToFile";
import swal from "sweetalert2";
import axios from "axios";

export default function SelectVideoDialog({ open, onClose, onSuccess, setVideoStatus }) {
  const drop = React.useRef();
  const drag = React.useRef();
  const fileSelectorRef = React.useRef();
  const thumbnailSelectRef = React.useRef();

  const [selectedFile, setSelectedFile] = React.useState(null);
  const [showAddDetails, setShowAddDetails] = React.useState(false);
  const [showEditor, setShowEditor] = React.useState(false);
  const [dragging, setDragging] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const [uploaded, setUploaded] = React.useState(false);
  const [isDraft, setIsDraft] = React.useState(false);
  const [allCategories, setAllCategories] = React.useState([]);
  const [loadingCategory, setLoadingCategory] = React.useState(false);

  const [videoCover, setVideoCover] = React.useState(null);
  const [thumbnail, setThumbnail] = React.useState(null);
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [tags, setTags] = React.useState("");
  const [credits, setCredits] = React.useState("");

  const [uploadingPercent, setUploadingPercent] = React.useState(0);
  const [visibleProgress, setVisibleProgress] = React.useState("0%");
  const [uploadSpeed, setUploadSpeed] = React.useState("0 MBps");


  const [uploadedVodcast, setUploadedVodcast] = React.useState(null);

  async function getData(url = "", methods = "") {
    const response = await fetch(url, {
      method: methods,
      headers: {
        "Content-Type": "application/json",
        authorization:
          localStorage.getItem("auth_token") ||
          sessionStorage.getItem("auth_token"),
      },
    });
    return response.json();
  }

  async function postData(url = "", data) {
    const response = await fetch(url, {
      method: "POST",
      body: data,
      headers: {
        // "Content-Type": "multipart/form-data",
        authorization:
          localStorage.getItem("auth_token") ||
          sessionStorage.getItem("auth_token"),
      },
    });
    return response.json();
  }

  async function postData1(url = "", data) {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        authorization:
          localStorage.getItem("auth_token") ||
          sessionStorage.getItem("auth_token"),
      },
    });
    return response.json();
  }


  React.useEffect(() => {
    setLoadingCategory(true);
    getData(
      `${process.env.REACT_APP_BASE_URL}/admin/get-vodcast-categories`,
      "GET"
    ).then((res) => {
      setLoadingCategory(false);
      if (res.status == true) {
        setAllCategories(res?.data);
      } else {
        console.log("incorrect");
      }
    });
  }, []);


  const [selectedLanguage, setSelectedLanguage] = useState("");

  const languages = [
    { id: 1, name: "Kannada" },
    { id: 2, name: "English" },
    { id: 3, name: "Hindi" },
    { id: 4, name: "Bengali" },
    { id: 5, name: "Marathi" },
    { id: 6, name: "Telugu" },
    { id: 7, name: "Tamil" },
    { id: 8, name: "Gujarati" },
    { id: 9, name: "Urdu" },
    { id: 10, name: "Bhojpuri" },
    { id: 11, name: "Malayalam" },
    { id: 12, name: "Odia" },
    { id: 13, name: "Punjabi" },
    { id: 14, name: "Rajasthani" },
    { id: 15, name: "Chhattisgarhi" },
    { id: 16, name: "Assamese" },
    { id: 17, name: "Maithili" },
    { id: 18, name: "Magahi" },
    { id: 19, name: "Haryanvi" },
    { id: 20, name: "Khortha/Khotta" },
    { id: 21, name: "Marwari" },
    { id: 22, name: "Santali" },
    { id: 23, name: "Kashmiri" },
    { id: 24, name: "Bundeli/Bundel Khandi" },
    { id: 25, name: "Malvi" },
    { id: 26, name: "Sadan/Sadri" },
    { id: 27, name: "Mewari" },
    { id: 28, name: "Awadhi" },
    { id: 29, name: "Wagdi" },
    { id: 30, name: "Lamani/Lambadi" },
    { id: 31, name: "Pahari" },
    { id: 32, name: "Bhili/Bhilodi" },
    { id: 33, name: "Hara/Harauti" },
    { id: 34, name: "Nepali" },
    { id: 35, name: "Gondi" },
    { id: 36, name: "Bagheli/Baghel Khandi" },
    { id: 37, name: "Sambalpuri" },
    { id: 38, name: "Dogri" },
    { id: 39, name: "Garhwali" },
    { id: 40, name: "Nimadi" },
    { id: 41, name: "Surjapuri" },
    { id: 42, name: "Konkani" },
    { id: 43, name: "Kumauni" },
    { id: 44, name: "Kurukh/Oraon" },
    { id: 45, name: "Tulu" },
    { id: 46, name: "Manipuri" },
    { id: 47, name: "Surgujia" },
    { id: 48, name: "Sindhi" },
    { id: 49, name: "Bagri" },
    { id: 50, name: "Ahirani" },
    { id: 51, name: "Banjari" },
    { id: 52, name: "Brajbhasha" },
    { id: 53, name: "Dhundhari" },
    { id: 54, name: "Bodo/Boro" },
    { id: 55, name: "Ho" },
    { id: 56, name: "Gojri/Gujjari/Gujar" },
    { id: 57, name: "Mundari" },
    { id: 58, name: "Garo" },
    { id: 59, name: "Kangri" },
    { id: 60, name: "Khasi" },
    { id: 61, name: "Kachchhi" },
  ];

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    console.log("Selected Language:", language);
  };

  const uploadVodcast = () => {
    setIsDraft(false);
    setUploading(true);
    let FD = new FormData();
    FD.append("title", title);
    FD.append("caption", description);
    FD.append("category", category);
    FD.append("user_id", localStorage.getItem("user_id"));
    FD.append("tags", tags);
    FD.append("credits", credits);
    FD.append("file", selectedFile);
    FD.append("type", "video");
    FD.append("thumbnail", thumbnail || videoCover);
    /* postData(`${process.env.REACT_APP_BASE_URL}/vodcast/create`, FD)
      .then((res) => {
        setUploading(false);
        if (res.status == true) {
          setUploaded(true);
          onSuccess && onSuccess();
        } else {
          alert("FAILED TO UPLOAD");
        }
      })
      .catch((err) => console.log("UPLOAD ERROR", err)); */

    setVisibleProgress("100%");

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/vodcast/create`, FD, {
        headers: {
          authorization:
            localStorage.getItem("auth_token") ||
            sessionStorage.getItem("auth_token"),
        },
        onUploadProgress: (data) => {
          setUploadingPercent(Math.round((data.loaded / data.total) * 100));
          setUploadSpeed(parseFloat((data.rate ?? 0) * 0.000001).toFixed(2) + " MB/s");
        },
      })
      .then((res) => {
        console.log(res);
        setUploading(false);
        if (res.status == 201) {
          setUploaded(true);

          setUploadedVodcast(res.data.data._id);

          getVodcastStatus(res.data.data._id);

        } else {
          alert("FAILED TO UPLOAD");
        }
        setUploadingPercent(0);
        setVisibleProgress("0");
        setUploadSpeed("");
      })
      .catch((error) => {
        // toast.error("error");
        console.log("UPLOAD ERROR", error);
        setUploadingPercent(0);
        setVisibleProgress("0");
      });
  };


  const getVodcastStatus = (vodcastId) => {

    console.log('Status');

    let data = {
      '_id': vodcastId,

    };
    console.log(data);

    postData1(`${process.env.REACT_APP_BASE_URL}/vodcast/video/status`, data).then(
      (data) => {
        if (data.status == true) {

          console.log('Data');
          console.log(data.data);

          if (data.data.video_status == 'completed') {

            setVideoStatus('completed');

            setTimeout(() => {
              setVideoStatus('none');
            }, 10000);

            onSuccess && onSuccess();


          } else {
            setVideoStatus('processing');

            setTimeout(() => {
              getVodcastStatus(vodcastId);
            }, 20000);
          }
          /* setLoader(false);
          localStorage.setItem("forgot-otp", otp);
          window.open("/changeForgotPassword", "_self"); */
        } else {
          // setLoader(false);
          // toast.error(data.message);
        }
      }
    );
  }

  const saveToDraft = () => {
    setIsDraft(true);
    setUploading(true);
    let FD = new FormData();
    FD.append("title", title);
    FD.append("caption", description);
    FD.append("category", category);
    FD.append("user_id", localStorage.getItem("user_id"));
    FD.append("tags", tags);
    FD.append("credits", credits);
    FD.append("file", selectedFile);
    FD.append("type", "video");
    FD.append("thumbnail", thumbnail || videoCover);
    postData(`${process.env.REACT_APP_BASE_URL}/vodcast/save-to-draft`, FD)
      .then((res) => {
        setUploading(false);
        if (res.status == true) {
          setUploaded(true);
          onSuccess && onSuccess();
        } else {
          alert("FAILED TO SAVE");
        }
      })
      .catch((err) => console.log("UPLOAD ERROR", err));
  };

  React.useEffect(() => {
    if (!open) {
      setSelectedFile(null);
    }
  }, [open]);

  const isValid =
    ["video/mp4", "video/mov", "video/quicktime", "video/avi"].includes(
      selectedFile?.type
    ) || false;

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();

    !isValid && setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    const { files } = e.dataTransfer;

    if (files && files.length) {
      setSelectedFile(files[0]);
      const cover = await getVideoCover(files?.[0]);
      const thumbnailFile = blobToFile(cover, `${files?.[0]?.name}.jpeg`);
      setVideoCover(thumbnailFile);
    }
  };

  React.useEffect(() => {
    drop.current?.addEventListener("dragover", handleDragOver);
    drop.current?.addEventListener("drop", handleDrop);
    drag.current?.addEventListener("dragenter", handleDragEnter);
    drag.current?.addEventListener("dragleave", handleDragLeave);

    return () => {
      drop.current?.removeEventListener("dragover", handleDragOver);
      drop.current?.removeEventListener("drop", handleDrop);
      drag.current?.removeEventListener("dragenter", handleDragEnter);
      drag.current?.removeEventListener("dragleave", handleDragLeave);
    };
  }, [drop.current, drag.current]);

  const openSelector = (e) => {
    !isValid && fileSelectorRef.current.click();
  };

  const onSelected = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    setSelectedFile(file);
    const cover = await getVideoCover(file);
    const thumbnailFile = blobToFile(cover, `${file.name}.jpeg`);
    setVideoCover(thumbnailFile);
  };

  const onThumbnailSelected = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    setThumbnail(file);
  };

  const onDiscardClick = () => {
    let yes = window.confirm("Are you sure to discard the upload");
    if (yes) {
      onClose();
    }
  };

  const checkDetailsAndContinue = () => {
    if (!title || !description || !category) {
      return alert("Please provide required fields");
    }
    setShowEditor(true);
  };

  // React.useEffect(() => {
  //   if (uploading) {
  //     setTimeout(() => {
  //       setUploading(false);
  //       setUploaded(true);
  //     }, 6000);
  //   }
  // }, [uploading]);

  if (showEditor) {
    return (
      <Modal
        open={true}
        style={{
          zIndex: 9999,
          position: "absolute",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          backdropFilter: "blur(5px)",
          overflow: "hidden",
          overflowY: "scroll",
        }}
      >
        <ModalDialog
          // slotProps={{
          //   root: {
          //     style: { top: "35%" },
          //   },
          // }}
          sx={{
            overflowY: "scroll",
            width: { xs: "100%" },
            width: { xs: "100%" },
            height: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
            // height: "100%",
            borderRadius: "xs",
            p: 2,
            boxShadow: "xs",
            background: "#222020",
            outlineWidth: 0,
            justifyContent: "flex-start",
          }}
        >
          <Grid xs={12} container sx={{ width: "100%", height: "100%" }}>
            <Grid xs={12} md={1.5} sx={{ mb: 2 }}>
              <Button
                onClick={() => setShowEditor(false)}
                size="sm"
                color="neutral"
                sx={{
                  p: 0,
                  ":hover": {
                    background: "transparent",
                    color: "orange",
                  },
                }}
                startDecorator={<RiArrowLeftLine size={22} />}
                variant="plain"
              >
                Back To Details
              </Button>
            </Grid>

            <Grid
              xs={12}
              md={8}
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
              }}
            >
              <VideoEditor video={selectedFile} thumbnail={videoCover} />
            </Grid>
            <Grid xs={12} md={2.5} sx={{ mt: 3, mb: 2, p: 3 }}>
              <Stack
                spacing={3}
                sx={{
                  height: "100%",
                  flexDirection: { xs: "row", md: "column" },
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  onClick={() => {
                    swal
                      .fire({
                        text: "Save the video to your draft?",
                        showCancelButton: true,
                        cancelButtonText: "Cancel",
                        confirmButtonText: "Save",
                        reverseButtons: true,
                        background: "#232323",
                        color: "#fff",
                      })
                      .then((result) => {
                        if (result.isConfirmed) {
                          setIsDraft(true);

                          setShowAddDetails(false);
                          setShowEditor(false);
                          saveToDraft();
                        }
                      });
                  }}
                  size="md"
                  color="neutral"
                  sx={{
                    background: "#262222",
                    color: "#fff",
                    borderRadius: 50,
                    px: 5,
                    fontSize: 12,
                    ":hover": {
                      background: constants.colors.disabled,
                    },
                  }}
                >
                  SAVE AS DRAFT
                </Button>
                <Button
                  onClick={() => {
                    setShowAddDetails(false);
                    setShowEditor(false);
                    uploadVodcast();
                  }}
                  size="md"
                  color="neutral"
                  sx={{
                    background: constants.colors.primary,
                    color: "#fff",
                    borderRadius: 50,
                    px: 5,
                    fontSize: 12,
                  }}
                >
                  UPLOAD VODCAST
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </ModalDialog>
      </Modal>
    );
  }

  if (uploading) {
    return <UploadingView isDraft={isDraft} visibleProgress={visibleProgress} uploadingPercent={uploadingPercent} uploadSpeed={uploadSpeed} />;
  }

  if (uploaded) {
    return <SuccessView onClose={onClose} isDraft={isDraft} />;
  }

  return (
    <div
      ref={drag}
      style={{
        zIndex: 9999,
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backdropFilter: "blur(5px)",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          background: constants.colors.secondary,
          opacity: 0.7,
        }}
      />
      <input
        type="file"
        accept="video/mp4,video/*"
        ref={fileSelectorRef}
        style={{ display: "none" }}
        onChange={onSelected}
      />

      <ModalDialog
        slotProps={{
          root: {
            style: {
              top: "35%",
            },
          },
        }}
        sx={{
          maxWidth: { xs: 400, sm: 500 },
          minWidth: { xs: "80%", sm: 400, md: 500, lg: 500 },
          minHeight: 300,
          borderRadius: "xs",
          p: 3,
          boxShadow: "xs",
          display: "flex",
          flexDirection: "column",
          background: "#222020",
          outlineWidth: 0,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {dragging && (
          <Box
            ref={drop}
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              position: "absolute",
              background: constants.colors.primary + "30",
              borderWidth: 1,
              borderColor: "red",
              backdropFilter: "blur(3px)",
              zIndex: 10,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RiDragDropLine />
            <Typography sx={{}}>Drop Here</Typography>
          </Box>
        )}
        <DialogContent sx={{ width: "100%" }}>
          {!isValid && <ModalClose onClick={onClose} />}
          {!isValid && (
            <Box
              ref={drop}
              onClick={openSelector}
              sx={{
                flex: 1,
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <FaRegFileVideo size={30} color="#fff" />
              <Typography
                sx={{
                  color: "#fff",
                  fontWeight: "600",
                  fontSize: { xs: 12, sm: 16, md: 18, lg: 18 },
                  pt: 0.6,
                }}
              >
                Upload Video
              </Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontWeight: "600",
                  fontSize: { xs: 6, sm: 8, md: 10, lg: 10 },
                  pt: 0.6,
                }}
              >
                Drag & Drop Video file
              </Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontWeight: "600",
                  fontSize: { xs: 6, sm: 8, md: 10, lg: 10 },
                }}
              >
                or click to select
              </Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontWeight: "600",
                  fontSize: { xs: 6, sm: 8, md: 10, lg: 10 },
                  position: "absolute",
                  bottom: 20,
                  color: "#B0B0B0",
                }}
              >
                Video must be in Mp4,Mov or Avi format
              </Typography>
            </Box>
          )}
          {selectedFile && isValid && !showAddDetails && (
            <Box
              onClick={openSelector}
              sx={{
                flex: 1,
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Typography
                sx={{ width: "100%", fontWeight: "600", textAlign: "left" }}
              >
                Video Selected
              </Typography>
              <Stack
                direction="row"
                sx={{ width: "90%", alignItems: "center", flex: 1 }}
              >
                <Box sx={{ flex: 0.1 }}>
                  <FaRegFileVideo size={60} />
                </Box>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    px: 1,
                    flex: 3,
                  }}
                >
                  <Stack direction="row" sx={{ alignItems: "center" }}>
                    <Typography
                      fontSize={{ xs: 12, sm: 14, md: 16, lg: 16 }}
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "1",
                        WebkitBoxOrient: "vertical",
                        fontWeight: "700",
                      }}
                    >
                      {selectedFile?.name}
                    </Typography>{" "}
                    <IconButton onClick={() => setSelectedFile(null)}>
                      <RiCloseLine size={20} />
                    </IconButton>
                  </Stack>

                  <Typography fontSize={{ xs: 8, sm: 10, md: 12, lg: 12 }}>
                    {byteConverter(selectedFile?.size)}
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  width: "100%",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  mt: 5,
                  gap: 2,
                }}
              >
                <Button
                  onClick={onDiscardClick}
                  size="sm"
                  color="neutral"
                  sx={{
                    borderRadius: 50,

                    fontSize: { xs: 14 },
                    ":hover": {
                      background: "#121212",
                    },
                  }}
                >
                  DISCARD UPLOAD
                </Button>
                <Button
                  onClick={() => setShowAddDetails(true)}
                  size="sm"
                  color="primary"
                  sx={{
                    borderRadius: 50,

                    fontSize: { xs: 14 },
                    background: constants.colors.primary,
                  }}
                >
                  CONTINUE
                </Button>
              </Stack>
            </Box>
          )}
          {selectedFile && isValid && showAddDetails && (
            <Box
              onClick={openSelector}
              sx={{
                flex: 1,
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Stack
                direction="row"
                sx={{ width: "80%", alignItems: "center" }}
              >
                <FaRegFileVideo size={30} />
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    px: 1,
                    flex: 3,
                  }}
                >
                  <Stack direction="row" sx={{ alignItems: "center" }}>
                    <Typography
                      fontSize={{ xs: 10, sm: 10, md: 12, lg: 12 }}
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "1",
                        WebkitBoxOrient: "vertical",
                        fontWeight: "700",
                      }}
                    >
                      {selectedFile?.name}
                    </Typography>
                    <IconButton onClick={() => setSelectedFile(null)}>
                      <RiCloseLine size={16} />
                    </IconButton>
                  </Stack>

                  <Typography fontSize={{ xs: 6, sm: 6, md: 8, lg: 8 }}>
                    {byteConverter(selectedFile?.size)}
                  </Typography>
                </Stack>
              </Stack>
              <Stack sx={{ flex: 1, width: "80%", gap: 0.5, mt: 2 }}>
                <Typography level="h4" fontSize="sm" sx={{ mb: 1 }}>
                  Upload Details
                </Typography>
                <Box
                  width="100%"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    mb: 2,
                    borderWidth: 1,
                    borderColor: "#414040",
                    backgroundColor: "#242424",
                    borderRadius: 5,
                    overflow: "hidden",
                  }}
                >
                  <AspectRatio
                    sx={{ width: 140, height: "100%", overflow: "hidden" }}
                    ratio={16 / 9}
                  >
                    <img
                      src={
                        thumbnail
                          ? URL.createObjectURL(thumbnail)
                          : URL.createObjectURL(videoCover)
                      }
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                      alt="thumbnail_preview"
                    />
                  </AspectRatio>
                  <Stack sx={{ px: 2 }}>
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: "500",
                        color: "#ffffff80",
                        textAlign: "center",
                      }}
                    >
                      Continue with this thumbnail
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: "500",
                        color: "#ffffff80",
                        textAlign: "center",
                      }}
                    >
                      OR
                    </Typography>
                    <Button
                      onClick={() => {
                        thumbnailSelectRef.current.click();
                      }}
                      size="sm"
                      sx={{ fontSize: 10, minHeight: 20 }}
                      variant="plain"
                    >
                      Upload a thumbnail
                    </Button>
                  </Stack>
                </Box>

                <input
                  ref={thumbnailSelectRef}
                  style={{ display: "none" }}
                  type="file"
                  accept="image/*"
                  onChange={onThumbnailSelected}
                />
                <Input
                  size="sm"
                  placeholder={`Title *`}
                  color="neutral"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <Textarea
                  size="sm"
                  color="neutral"
                  placeholder="Description *"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  slotProps={{
                    input: {
                      multiple: true,
                      style: { minHeight: 60, verticalAlign: "top" },
                    },
                  }}
                />
                <Stack direction="row" spacing={1}>
                  <Select
                    size="sm"
                    placeholder="Category *"
                    defaultValue={""}
                    value={category}
                    onChange={(e, newValue) => setCategory(newValue)}
                    color="neutral"
                    sx={{ flex: 1 }}
                  >
                    {loadingCategory && <CircularProgress size="sm" />}
                    {!loadingCategory &&
                      allCategories?.map((item, index) => (
                        <Option value={item._id} key={index}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                  <Input
                    size="sm"
                    placeholder="Tag"
                    color="neutral"
                    value={tags}
                    onChange={(e) => setTags(e.target.value)}
                  />
                </Stack>
                <LanguageDropDown
            options={languages}
            placeholder="Choose a language"
            onChange={handleLanguageChange}
            value={selectedLanguage}
          />
                <Input
                  value={credits}
                  onChange={(e) => setCredits(e.target.value)}
                  size="sm"
                  placeholder="Music Credits"
                  color="neutral"
                  sx={{ mt: 2 }}
                />
              </Stack>
              <Stack
                direction="row"
                sx={{
                  width: "100%",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  mt: 5,
                  gap: 2,
                }}
              >
                <Button
                  onClick={onDiscardClick}
                  size="sm"
                  color="neutral"
                  sx={{
                    borderRadius: 50,
                    px: 3,
                    fontSize: { xs: 14 },
                    ":hover": {
                      background: "#121212",
                    },
                  }}
                >
                  DISCARD UPLOAD
                </Button>
                <Button
                  onClick={checkDetailsAndContinue}
                  size="sm"
                  color="primary"
                  sx={{
                    borderRadius: 50,
                    px: 5,
                    fontSize: { xs: 14 },
                    background: constants.colors.primary,
                  }}
                >
                  CONTINUE
                </Button>
              </Stack>
            </Box>
          )}
        </DialogContent>
        {selectedFile && !isValid && (
          <Alert
            sx={{
              background: "#ffffff10",
              color: "yellow",
              py: 0.5,
              borderRadius: 0,
              fontSize: 12,
              textAlign: "center",
            }}
            variant="soft"
          >
            Invalid Video Format! {<br />}Please select supported video.
          </Alert>
        )}
      </ModalDialog>
      {/* </Modal> */}
    </div >
  );
}
