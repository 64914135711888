// import {} from ""
import style from "./privacy.module.css";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

function PrivacyPage() {
  // Context
  const items = [
    {
      id: 1,
      title: "1. About",
    },
    {
      id: 2,
      title: "2. Your personal data rights and controls",
    },
    {
      id: 3,
      title: "3. Personal data we collect about you",
    },
    {
      id: 4,
      title: "4. Our purpose for using your personal data",
    },
    // {
    //   id: 5,
    //   title: "5. Accessing User Device Media Storage for Video Uploading",
    // },
    {
      id: 5,
      title: "5. Disclosing your personal data",
    },
    {
      id: 6,
      title: "6. Data retention",
    },
    {
      id: 7,
      title: "7. Keeping your personal data safe",
    },
    {
      id: 8,
      title: "8. In-App Purchase Terms",
    },
    {
      id: 9,
      title: "9. Children",
    },
    {
      id: 10,
      title: "10-. Changes to this policy",
    },
    {
      id: 11,
      title: "11.	How to contact us",
    },
  ];

  return (
    <div className={style.container}>
      <div className="w-full py-12 flex flex-col justify-between align-middle">
        <div className="md:px-32 px-4 flex flex-col justify-start align-middle">
          <Link
            to={"/"}
            className="flex gap-3 cursor-pointer hover:transform-cpu"
          >
            <FaArrowLeft />
            {/* <p className="text-lg font-semibold uppercase">Back To Home</p> */}
          </Link>
        </div>
        <br />
        {/* Title Header  */}
        <div className="md:px-32 px-4 flex flex-col justify-start align-middle">
          <h2 className="text-4xl font-semibold ">Music book Privacy Policy</h2>
          <span className="flex gap-1 text-gray-500">
            <p>Last Updated On:</p>
            <p>12</p>
          </span>
        </div>
        {/* Context Menu  */}
        <div className="w-1/2 px-32 flex flex-col justify-start align-middle">
          <p>
            <strong>Context</strong>
          </p>
          {items.map((item) => (
            <ol key={item.id} className="Context text-white">
              <li className="text-white/40 cursor-pointer hover:text-white">
                {item.title}
              </li>
            </ol>
          ))}
        </div>
        {/* About  */}
        <div className="w-full mt-8 flex flex-col justify-start align-middle">
          <div className="flex flex-col px-32 justify-start align-middle">
            <h1 className="text-2xl font-semibold">1. About</h1>
            <p className="text-xl">
              This privacy policy describes how we process your personal data at
              Music book™. From now on, <br /> we&rsquo;ll call it the{" "}
              <strong>Policy</strong>.
            </p>
            <br />
            <p>
              The Music book™ website, all mobile software applications made
              available on the Music book™ website or for download (each an
              "App" and collectively, the "Apps"), and any associated websites
              or other online properties owned or controlled by Music book™ to
              which this Policy is linked (collectively, the Websites and the
              Apps, the Websites) are all covered by our Policy unless otherwise
              stated.
            </p>
            <br />
            <p>
              Please take the time to read the Terms of Service, which outlines
              the rules that apply to the Services.
            </p>
            <br />
            <div>
              <p>It applies to your use of:</p>
              <br />
              <ol>
                <li>- Your use of Music book on any device</li>
                <li>- The personalization of your use experience</li>
                <li>- The infrastructure required to provide our services</li>
                <li>
                  - Connect your Music book account with another application
                </li>
                <li>
                  - Both our free or paid streaming options (each a "Service
                  Option")
                </li>
              </ol>
            </div>
            <br />
            <p>
              Music book™, Inc. and any of its subsidiaries are referred to as "
              Music book™ " (or "we," "us," and "our") in this document. Please
              read the Privacy Notice for Indian Residents. if you are a
              resident of India
            </p>
            <br />
            <h5 className="font-semibold">INFORMATION WE USE</h5>
            <p>
              We collect some of the basic information to register our users.
              Music book has two modules where users can choose to be
              professional or personal Here is some of the information, we
              collect to register a user.
            </p>
            <br />
            <ol>
              <li>- Profile Image</li>
              <li>- Name</li>
              <li>- Email</li>
              <li>- Phone Number</li>
              <li>- Date of birth</li>
              <li>- Address</li>
              <li>- Password</li>
            </ol>
            <br />
            <p>
              We also use third-party social login to register our users to use
              our services such as Google login and other social login in the
              future.
            </p>
            <p>----</p>
            <p>
              Music book collect and extrapolate demographic data. We also
              collect data such as liked content, most viewed content, shared
              videos, upload video type, and interactive type of videos to give
              a tailored experience to our users.
            </p>
            <p>
              From time to time, we may develop new or offer additional services
              and updates. They’ll also be subjected to this Policy unless
              stated otherwise when we introduce them. In some cases, we may
              provide different or additional policies or notices about our
              information practices.
            </p>
            <p>This Policy is not…</p>
            <ol>
              <li>
                - The Music book <strong>Terms and Conditions</strong>, which is
                a separate document. The Terms of Use outline the legal contract
                between you and Music book for using Music book services. It
                also describes the rules of Music book and your own rights
              </li>
              <li>
                - This Policy doesn’t contain any{" "}
                <strong>Refund and Cancellation</strong> or
                <strong>Users Agreement</strong> Both are <br /> which kept as a
                separate document.
              </li>
            </ol>
          </div>
        </div>
        {/* 2 Your Personal data rights and controls */}
        <div className="w-full mt-8 flex flex-col justify-start align-middle">
          <div className="flex flex-col px-32 justify-start align-middle">
            <h1 className="text-2xl font-semibold">
              2. Your Personal data rights and controls
            </h1>
            <p className="text-xl">
              Privacy laws give certain rights to individuals over their
              personal data.
            </p>
            <br />
            <div>
              <p>The Points below explains:</p>
              <br />
              <ol>
                <li>1. Your rights</li>
                <li>2. Circumstances when they Apply</li>
                <li>3. How to use them</li>
              </ol>
            </div>
            <br />
            <table class="border-collapse border border-slate-500 ...">
              <thead>
                <tr className="bg-blue-800">
                  <th class="border py-4 text-left px-4 border-slate-600 ...">
                    Your Rigth
                  </th>
                  <th class="border py-4 text-left px-4 border-slate-600 ...">
                    Circumstance
                  </th>
                  <th class="border py-4 text-left px-4 border-slate-600 ...">
                    How To Use Them
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <strong>Be Informed</strong>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    Be Informed of the personal data we process about you and
                    how we process it
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>We inform you</p>
                    <ul>
                      <li>- Through this policy</li>
                      <li>
                        - By answering your specific question and request when
                        you contact us
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <strong>Know / Access</strong>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    Request to know and access the personal data we process
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      To request a copy of your personal data from Music book,
                      you can write a mail or contact us
                    </p>
                    {/* <ul>
                      <li>- Through this policy</li>
                      <li>
                        - By answering your specific question and request when
                        you contact us
                      </li>
                    </ul> */}
                  </td>
                </tr>
                <tr>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <strong>Correction</strong>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    Request to know and access the personal data we process
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      You can edit your user data “Edit Profile” on your profile
                      page
                    </p>
                    {/* <ul>
                      <li>- Through this policy</li>
                      <li>
                        - By answering your specific question and request when
                        you contact us
                      </li>
                    </ul> */}
                  </td>
                </tr>
                <tr>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <strong>Deletion</strong>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>Request that we delete certain of your personal data:</p>
                    <p>
                      Please take note there are situations where Music book is
                      unable to delete your data, for example, when:
                    </p>
                    <ul>
                      <li>
                        - It's still necessary to process the data for the
                        purpose we collected it for
                      </li>
                      <li>
                        - We have an overriding interest in continuing to
                        protect our services from fraud
                      </li>
                      <li>
                        - Music book has a legal obligation to keep the data, or
                        Music book need that data to establish, exercise, or
                        defend legal claims. For Example, if there is an
                        unresolved issue relating to your account
                      </li>
                    </ul>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      There are several ways you can erase personal data from a
                      Music book:
                    </p>
                    <ul>
                      <li>
                        - to remove audio and video content from your profile
                        select the relevant content and choose to remove it. For
                        example, you can remove the published video from your
                        profile, or remove any other content from your managed
                        vodcast
                      </li>
                      <li>
                        - to request the deletion of your other personal data
                        from Music book, follow the steps on our support page.
                        This data includes your Users Data, Usage Data, and
                        other data listed in Section 3 ‘Personal data we collect
                        about you’
                      </li>
                      <li>- you can also contact us to request deletion</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <strong>Restriction</strong>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      Request that we stop processing all or some of your
                      personal data. You can do this if:
                    </p>
                    <ul>
                      <li>- Your personal data is incorrect</li>
                      <li>- Our processing is unlawful</li>
                      <li>
                        - You can request that we stop this processing
                        temporarily or permanently
                      </li>
                    </ul>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      You can exercise this right by contacting us or writing
                      mail to support@musicbook.co.in
                    </p>
                    {/* <ul>
                      <li>
                        - to remove audio and video content from your profile
                        select the relevant content and choose to remove it. For
                        example, you can remove the published video from your
                        profile, or remove any other content from your managed
                        vodcast
                      </li>
                      <li>
                        - to request the deletion of your other personal data
                        from Music book, follow the steps on our support page.
                        This data includes your Users Data, Usage Data, and
                        other data listed in Section 3 ‘Personal data we collect
                        about you’
                      </li>
                      <li>- you can also contact us to request deletion</li>
                    </ul> */}
                  </td>
                </tr>
                <tr>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <strong>Data portability</strong>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      Request a copy of your personal data in electronic format
                      and the right to transmit that personal data for use in
                      another party's services
                    </p>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      For more information about how to exercise the right to
                      portability, see ‘Know/Access’ above.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <strong>Not be subject to automated decision-making</strong>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      Not be subject to a decision based solely on automated
                      decision-making (decisions without human involvement),
                      including profiling, where the decision would have a legal
                      effect on you or produce a similarly significant effect.
                    </p>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      Music book does not carry out this type of automated
                      decision-making in the Music book web and app.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <strong>Withdrawal of consent</strong>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      Withdraw your consent to us collecting or using your
                      personal data. You can do this if Music book is processing
                      your personal data on the legal basis of consent.
                    </p>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>To withdraw your consent, you can:</p>
                    <ul>
                      <li>
                        - adjust the relevant control on the Music book app and
                        Web
                      </li>
                      <li>- contact us</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <h1 className="text-2xl font-semibold">
              Accessing User Device Media Storage for Video Uploading
            </h1>
            <p className="text-xl">
              At Music book, our platform facilitates video uploading where
              users have the option to select and upload videos from their
              device storage. This access is initiated and controlled entirely
              by the user through explicit actions within the app. Users retain
              the ability to manage their uploaded content, including the
              deletion of videos from our platform, ensuring privacy and data
              security.
            </p>
            <h4 className="text-xl mt-4">Implementation Approach:</h4>
            <p className="text-xl uppercase">MediaStore API Integration:</p>
            <p className="text-xl">
              To adhere to best practices and enhance user security, Music book
              primarily utilizes the MediaStore API for accessing media files on
              user devices. The MediaStore API ensures secure and controlled
              access to media files such as videos stored on the device.
            </p>
            <p className="text-xl uppercase">
              Handling Files Outside MediaStore:
            </p>
            <p className="text-xl">
              While our preference is to use the MediaStore API for most media
              file interactions, there may be scenarios where our app needs to
              handle files not covered by MediaStore. In such cases, we ensure
              that access is limited to essential functions required for app
              operation and user experience.
            </p>
            <p className="text-xl uppercase">
              Limited All Files Access Permission:
            </p>
            <p className="text-xl">
              Recognizing the sensitivity of unrestricted file access, Music
              book strictly limits the use of All Files Access permission to
              necessary and justified functions within the app. This approach
              aligns with our commitment to user privacy and security.
            </p>
            <br />
            <h1 className="text-2xl font-semibold">
              Compliance and User Control:
            </h1>
            <p className="text-xl">
              Music book is dedicated to compliance with Google Play policies
              and regulatory requirements concerning data privacy and security.
              Our practices are designed to uphold user control over their data,
              including transparent management of media access permissions
              within the app.
            </p>
            <br />
            <h1 className="text-2xl font-semibold">
              Verifying requests, generally
            </h1>
            <p className="text-xl">
              To verify a consumer's request to know whether to delete or
              request correction, we ask you to provide certain details related
              to the Music book account. Please note, if you do not have a Music
              book account, we will not have enough information about you to
              verify your identity and respond to your requests, as we do not
              keep sufficient information necessary to re-identify and link you
              to prior visits to the Music book mail.
            </p>
            <br />
            <h1 className="text-2xl font-semibold">
              Tailored Advertising controls (Upcoming):
            </h1>
            <p className="text-xl">What is tailored advertising?</p>
            <ul>
              <li>
                - This is when we use information about your use of the Music
                book service to tailor ads to be more relevant to you. This is
                known as interest-based advertising, Targeted advertising, or
                ‘Sharing’ for cross-content behavioral advertising.{" "}
              </li>
              <li>
                - An example of a tailored advertising partner has information
                suggesting you like cats. This could enable us to show you ads
                about cats.
              </li>
            </ul>
            <br />
          </div>
          {/* Inside 2  */}
        </div>
        {/* 3 Personal Data we collect about you  */}
        <div className="w-full mt-8 flex flex-col justify-start align-middle">
          <div className="flex flex-col px-32 justify-start align-middle">
            <h1 className="text-2xl font-semibold">
              3. Personal Data we collect about you
            </h1>
            <p className="text-xl">
              These tables set out the categories of personal data we collect
              from you.
            </p>
            <p className="text-xl italic font-semibold">
              Collected when you sign up for Music book or when you update your
              account
            </p>
            <br />
            <table class="border-collapse border border-slate-500 ...">
              <thead>
                <tr className="bg-blue-800">
                  <th class="border py-4 text-left px-4 border-slate-600 ...">
                    Categories
                  </th>
                  <th class="border py-4 text-left px-4 border-slate-600 ...">
                    Description
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <strong>Users Data</strong>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      Personal data that we need to create your Music book
                      account and that enables you to use our services. Here are
                      the data we collect:
                    </p>
                    <ul>
                      <li>• Profile Name / Full Name</li>
                      <li>• Profile Image</li>
                      <li>• Email Address</li>
                      <li>• Password</li>
                      <li>• Phone Number</li>
                      <li>• Date of Birth</li>
                      <li>• Gender</li>
                      <li>• Address</li>
                      <li>• Pin Code</li>
                      <li>• Country</li>
                      <li>• State</li>
                    </ul>
                    <p>
                      We receive some of this data from you e.g. from the signup
                      form or account page. We also collect some of this data
                      from your device e.g. country or region. For more
                      information about how we collect and use this data, see
                      ‘Your general (non-precise) location’ in the Usage Data
                      category.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <strong>Data</strong>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      We may ask for and process your street address for the
                      following reasons:
                    </p>
                    <ul>
                      <li>• To Deliver notices which are required by law</li>
                      <li>• To Deliver support options</li>
                      <li>• For billing and tax administration</li>
                    </ul>
                    <p>
                      In some cases, we may use a third-party application to
                      help you verify you address, such as Google Maps
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <p className="text-xl italic font-semibold">
              Collected through your use of the Music book service
            </p>
            <br />
            <table class="border-collapse border border-slate-500 ...">
              <thead>
                <tr className="bg-blue-800">
                  <th class="border py-4 text-left px-4 border-slate-600 ...">
                    Categories
                  </th>
                  <th class="border py-4 text-left px-4 border-slate-600 ...">
                    Description
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <strong>Usage Data</strong>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      Personal data collected and processed about you when
                      you’re accessing or using the Music book Service. There
                      are a few types of information this includes, listed in
                      the following sections.
                    </p>
                    <p>
                      <strong>Information about how you use Music book</strong>
                    </p>
                    <p>Examples include:</p>
                    <ul>
                      <li>
                        • Information about your Music book Service Option
                      </li>
                      <li>
                        • your actions with the Music book Service (including
                        date and time), such as:
                        <ul>
                          <li>○ Search queries</li>
                          <li>○ Streaming history</li>
                          <li>
                            ○ Your uploaded content (Video, Audio and Image)
                          </li>
                          <li>○ Browsing history</li>
                          <li>○ Account setting</li>
                        </ul>
                      </li>
                      <li>• Interaction with other Music book services</li>
                      <li>
                        • inferences (i.e., our understanding) of your interests
                        and preferences based on your usage of the Music book
                        service
                      </li>
                      <li>
                        • content you post to any part of the Music book
                        Service. For example: images, audio, text, titles,
                        descriptions, communications, and other types of content
                      </li>
                    </ul>
                    <p>
                      <strong>Your technical data</strong>
                    </p>
                    <ul>
                      <li>• URL information</li>
                      <li>
                        • online identifiers such as cookie data and IP
                        addresses
                      </li>
                      <li>
                        • information about the devices you use such as:
                        <ul>
                          <li>○ device IDs</li>
                          <li>
                            ○ network connection type (e.g., WI-FI, 4G, LTE,
                            Bluetooth)
                          </li>
                          <li>○ provider</li>
                          <li>○ network and device performance</li>
                          <li>○ language</li>
                          <li>
                            ○ information enabling digital rights management
                          </li>
                          <li>○ operating system</li>
                          <li>○ Music book application version</li>
                        </ul>
                      </li>
                      <li>
                        • information that enables us to discover and connect
                        with third-party devices and applications. Examples of
                        this information are the device name, device
                        identifiers, brand, and version. Examples of third-party
                        devices and applications are:
                        <ul>
                          <li>
                            ○ devices on your Wi-Fi network (such as speakers)
                            that can connect to the Music book Service
                          </li>
                          <li>
                            ○ devices your operating system makes available when
                            connecting via Bluetooth, plugin, and installation
                          </li>
                        </ul>
                      </li>
                      <p>
                        <strong>Your general (non-precise) location</strong>
                      </p>
                      <p>
                        Your general location includes country, region, or
                        state. We may learn this from technical data (e.g...,
                        your IP address, language setting of your device) or
                        payment currency.
                      </p>
                      <p>
                        <strong>We need this to:</strong>
                      </p>
                      <li>
                        ● Meet geographic requirements in our agreements with
                        the owners of content on the Music book Service
                      </li>
                      <li>
                        ● deliver content and advertising that’s relevant to you
                      </li>
                    </ul>
                    <p>
                      <strong>Your device sensor data</strong>
                    </p>
                    <p>
                      Motion-generated or orientation-generated device sensor
                      data if needed to provide features of the Music book
                      Service that require this data. This is data that your
                      device collects about the way you move or hold your
                      device.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <strong>Payment and purchase data</strong>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      If you make any purchases from Music book or sign up for a
                      paid Service Option or a trial, we will need to process
                      your payment data.
                    </p>
                    <p>
                      The exact personal data collected and used will vary
                      depending on the payment method. It will include
                      information such as:
                    </p>
                    <ul>
                      <li>• name</li>
                      <li>• Date of birth</li>
                      <li>
                        • payment method type (e.g.., credit, debit card, UPI or
                        Pay later)
                      </li>
                      <li>
                        • if using a debit or credit card, the card type,
                        expiration date, and certain digits of your card number
                        Note: For security, we never store your full card number
                      </li>
                      <li>• ZIP/postal code</li>
                      <li>• Mobile phone number</li>
                      <li>• details of your purchase and payment history</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <strong>Survey and Research Data</strong>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      When you respond to a survey or take part in users
                      research, we collect and use the personal data you
                      provide.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <p>
              We receive some of the data mentioned above from third parties.
              The below table describes the categories of those third parties.
            </p>
            <p>
              <strong>
                Third-party sources that we receive your data from
              </strong>
            </p>
            <br />
            <table class="border-collapse border border-slate-500 ...">
              <thead>
                <tr className="bg-blue-800">
                  <th class="border py-4 text-left px-4 border-slate-600 ...">
                    Categories of third parties
                  </th>
                  <th class="border py-4 text-left px-4 border-slate-600 ...">
                    Description
                  </th>
                  <th class="border py-4 text-left px-4 border-slate-600 ...">
                    Data categories
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <strong>Authentication partners</strong>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      If you register for or log into the Music book Service
                      using another service, that service will send your
                      information to us. This information helps create your
                      account with us.
                    </p>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>Users Data</p>
                  </td>
                </tr>
                <tr>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <strong>
                      Third-party applications, services, and devices you
                      connect to your Music book account
                    </strong>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      If you connect your Music book account to a third-party
                      application, service, or device, we may collect and use
                      information from them. This collection is to make the
                      integration possible
                    </p>
                    <p>
                      These third-party apps, services, or devices may include:
                    </p>
                    <ul>
                      <li>- Social Media</li>
                      <li>
                        - Devices including:
                        <ul>
                          <li>- Audio (Speaker or headphone)</li>
                          <li>- Mobile or Tablet</li>
                          <li>- Bluetooth Devices</li>
                        </ul>
                      </li>
                    </ul>
                    <p>
                      We’ll ask your permission before we collect your
                      information from certain third parties.
                    </p>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>Users Data, Usage of Data</p>
                  </td>
                </tr>
                <tr>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <strong>Payment partners and Merchants</strong>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      If you choose to pay through third parties (e.g.... Cash
                      Free) or by invoice, we may get data from our payment
                      partners.
                    </p>
                    <p>This allows us to:</p>
                    <ul>
                      <li>- send Invoices</li>
                      <li>- Process your payment</li>
                      <li>- Give you what you’ve purchased</li>
                    </ul>
                    <p>
                      If we direct you to a merchant, we receive data from the
                      merchant that is related to your purchase. For example, we
                      might direct you to an artist’s merchandise store on a
                      third-party platform or to a third-party ticketing
                      website. Receiving this data allows us to:
                    </p>
                    <ul>
                      <li>• calculate any commissions owed to u</li>
                      <li>
                        • Analysis of the effectiveness of our partnership with
                        these merchant partners
                      </li>
                      <li>• understand your interests</li>
                    </ul>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>Payment and Purchase Data</p>
                  </td>
                </tr>
                <tr>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <strong>Advertising and marketing partners</strong>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      We receive inferences from certain advertising or
                      marketing partners. These inferences are the partners’
                      understanding of your interests and preferences. This
                      allows us to deliver more relevant ads and marketing.
                    </p>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>Usage Data</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <p>
              If you download the Music book mobile app and try Music book using
              a logged-out users experience, we will collect limited information
              about your usage of the Music book Service, including Usage Data.
              We do this to understand how you are accessing and using the
              Service. We also do this to ensure we provide the right experience
              for you, for example based on your country or region. If you
              decide to create a Music book account to experience our service in
              full, then we will combine this data with your Music book account
              data
            </p>
          </div>
          {/* Inside 3  */}
        </div>
        {/* 4 Our purpose for using your personal data  */}
        <div className="w-full mt-8 flex flex-col justify-start align-middle">
          <div className="flex flex-col px-32 justify-start align-middle">
            <h1 className="text-2xl font-semibold">
              4. Our purpose for using your personal data
            </h1>
            <p className="text-xl">The table below sets out:</p>
            <ul>
              <li>• our purpose for processing your personal data</li>
              <li>
                • our legal justifications (each called a legal basis) under
                data protection law, for each purpose
              </li>
              <li>
                • categories of personal data which we use for each purpose. See
                more about these categories in Section 3 “Personal data we
                collect about you”
              </li>
            </ul>
            <br />
            <table class="border-collapse border border-slate-500 ...">
              <thead>
                <tr className="bg-blue-800">
                  <th class="border py-4 text-left px-4 border-slate-600 ...">
                    Purpose for processing your data
                  </th>
                  <th class="border py-4 text-left px-4 border-slate-600 ...">
                    Legal basis that permits the purpose
                  </th>
                  <th class="border py-4 text-left px-4 border-slate-600 ...">
                    Categories of personal data used for the purpose
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      To provide the Music book in accordance with our contract
                      with you.
                    </p>
                    <p>For example, when we use your personal data to:</p>
                    <ul>
                      <li>• Set up an account for you</li>
                      <li>• Personalize your account, or</li>
                      <li>
                        • Provide the Music book when you download it onto your
                        device
                      </li>
                    </ul>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>Performance of a Contract</p>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <ul>
                      <li>• Users Data</li>
                      <li>• Street Address Data</li>
                      <li>• Usage Data</li>
                      <li>• Payment and Purchase Data</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>To provide further parts of the Music book service.</p>
                    <p>
                      For example, when we use your personal data to enable you
                      to share a link to Music book content with someone else
                    </p>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      Legitimate Interest Our legitimate interests here include:
                    </p>
                    <ul>
                      <li>
                        • keeping the Music book Service running and functional
                      </li>
                      <li>
                        • allowing users to access and use the Music book
                        Service
                      </li>
                    </ul>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <ul>
                      <li>• Users Data</li>
                      <li>• Street Address Data</li>
                      <li>• Usage Data</li>
                      <li>• Payment and Purchase Data</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      To provide certain additional voluntary features of the
                      Music book Service. When this is the case, we will ask for
                      your consent
                    </p>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>Consent</p>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <ul>
                      <li>• Users Data</li>
                      <li>• Survey and Research Data</li>
                      <li>• Usage Data</li>
                      <li>• Payment and Purchase Data</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      To diagnose, troubleshoot, and fix issues with the Music
                      book Service.
                    </p>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>Performance of a contract</p>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <ul>
                      <li>• Users Data</li>
                      <li>• Usage Data</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      To evaluate and develop new features, technologies, and
                      improvements to the Music book Service. For Example:
                    </p>
                    <ul>
                      <li>
                        • we use personal data to develop and improve our
                        personalized recommendation algorithms
                      </li>
                      <li>
                        • we analyze how our users react to a particular new
                        feature and see whether we should make any changes
                      </li>
                    </ul>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      Legitimate Interest Our legitimate interest here includes
                      developing and improving products and features for our
                      users
                    </p>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <ul>
                      <li>• Users Data</li>
                      <li>• Survey and Research Data</li>
                      <li>• Usage Data</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      For marketing or advertising where the law requires us to
                      collect your consent. For example, when we use cookies to
                      understand your interests or the law requires consent for
                      email marketing.
                    </p>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>Consent</p>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <ul>
                      <li>• Users Data</li>
                      <li>• Survey and Research Data</li>
                      <li>• Usage Data</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      For other marketing, promotion, and advertising purposes
                      where the law does not require consent.
                    </p>
                    <p>
                      For example, when we use your personal data to tailor
                      advertising to your interests.
                    </p>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      Legitimate Interest Our legitimate interests here include
                      using advertising to fund the Music book Service so that
                      we can offer much of it for free.
                    </p>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <ul>
                      <li>• Users Data</li>
                      <li>• Survey and Research Data</li>
                      <li>• Usage Data</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      To conduct research and surveys. For example, when we
                      contact our users to ask for your feedback
                    </p>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      Legitimate Interest Our legitimate interests here include
                      understanding more about how users think about and use the
                      Music book Service.
                    </p>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <ul>
                      <li>• Users Data</li>
                      <li>• Survey and Research Data</li>
                      <li>• Usage Data</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* Inside 4  */}
        </div>
        {/* 5 Sharing your personal data  */}
        <div className="w-full mt-8 flex flex-col justify-start align-middle">
          <div className="flex flex-col px-32 justify-start align-middle">
            <h1 className="text-2xl font-semibold">
              5. Sharing your personal data
            </h1>
            <p className="text-xl">
              This section sets out who receives personal data that is collected
              or generated through your use of the Music book Service
            </p>
            <p className="text-xl">
              The following personal data will always be publicly available on
              the Music book Service (except to any users you have blocked):
            </p>
            <ul>
              <li>• Your Profile Name</li>
              <li>• Your Profile Photo</li>
              <li>
                • other content you post on the Music book Service, and any
                associated titles, descriptions, and images
              </li>
              <li>• whom you follow on the Music book Service</li>
              <li>• who follows you on the Music book Service</li>
            </ul>
            <br />
            <p>
              You or another user can share certain information on third-party
              services, like social media or messaging platforms. This includes:
            </p>
            <ul>
              <li>• Your Profile</li>
              <li>
                • any content you post on Music book and details about that
                content
              </li>
            </ul>
            <br />
            <p>
              When this sharing occurs, the third-party service may store a copy
              of it to support their features.
            </p>
            <p>
              <strong>Personal data you may choose to share</strong>
            </p>
            <p>
              We will only share the following personal data with those outlined
              in the table below:
            </p>
            <ul>
              <li>
                • where you have chosen to use a Music book Service feature, or
                a third-party application, service or device, and we need to
                share personal data to enable this, or
              </li>
              <li>
                • if you otherwise grant us your permission to share the
                personal data. For example, you can do it by selecting the
                appropriate setting in the Music book Service or by giving your
                consent
              </li>
            </ul>
            <br />
            <table class="border-collapse border border-slate-500 ...">
              <thead>
                <tr className="bg-blue-800">
                  <th class="border py-4 text-left px-4 border-slate-600 ...">
                    Categories of recipients
                  </th>
                  <th class="border py-4 text-left px-4 border-slate-600 ...">
                    Categories of data you can choose to share
                  </th>
                  <th class="border py-4 text-left px-4 border-slate-600 ...">
                    Reason for sharing
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      Third party applications, services and devices you connect
                      to your Music book account
                    </p>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <ul>
                      <li>• Users Data</li>
                      <li>• Usage Data</li>
                    </ul>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      To connect your Music book account, or so that you can use
                      the Music book Service in connection with third party
                      applications, services or devices.
                    </p>
                    <p>
                      Examples of such third-party applications, services and
                      devices include:
                    </p>
                    <ul>
                      <li>• social media applications</li>
                      <li>• speaker devices</li>
                      <li>• Mobile and Tablet</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>Support Community</p>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <ul>
                      <li>• Users Data</li>
                      {/* <li>• Usage Data</li> */}
                    </ul>
                  </td>
                  <td class="border py-4 text-left px-4 border-slate-700 ...">
                    <p>
                      To enable you to use the Music book Support Community
                      service.
                    </p>
                    <p>
                      When you register for an account on the Music book Support
                      Community, we’ll ask you to create a profile name. This
                      will be publicly displayed to anyone who uses the Music
                      book Support Community. We’ll also display any questions
                      or comments you post.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* Inside 5  */}
        </div>
        {/* 6 Data retention  */}
        <div className="w-full mt-8 flex flex-col justify-start align-middle">
          <div className="flex flex-col px-32 justify-start align-middle">
            <h1 className="text-2xl font-semibold">7. Data retention</h1>
            <p className="text-xl">
              We keep your personal data only as long as necessary to provide
              you with the Music book Service and for Music book’s legitimate
              and essential business purposes, such as:
            </p>
            <ul>
              <li>• maintaining the performance of the Music book Service</li>
              <li>
                • making data-driven business decisions about new features and
                offerings
              </li>
              <li>• complying with our legal obligations</li>
              <li>• resolving disputes</li>
            </ul>
            <p className="text-xl">
              Here are some of the categories of our retention periods, and the
              criteria we use to determine them:
            </p>
            <ul>
              <li>
                <p>
                  <strong>• Data retained until you remove it</strong>
                </p>
                <p>
                  It's your right to request that we delete certain of your
                  personal data. See the section on Deletion in Section 2 'Your
                  personal data rights and controls' for more information, and
                  the circumstances in which we can act on your request.
                </p>
                <p>
                  You can also delete certain personal data directly from the
                  Music book Service: for example, you can edit or delete your
                  profile picture. Where users can see and update their personal
                  data themselves, we keep the information for as long as the
                  users choose unless one of the limited purposes described
                  below applies.
                </p>
              </li>
              <li>
                <p>
                  <strong>• Data that expires after a specific period</strong>
                </p>
                <p>
                  We have set certain retention periods so that some data
                  expires after a specific period. For example, personal data
                  you may input as part of search queries is generally deleted
                  after 90 days.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    • Data retained until your Music book account is deleted
                  </strong>
                </p>
                <p>
                  We keep some data until your Music book account is deleted.
                  Examples of this include your Music book username and profile
                  information. We also typically keep streaming history for the
                  life of an account, for example, to provide retrospective
                  playlists that users enjoy and personalized recommendations
                  that take listening habits into account (for example, Your
                  Time Capsule or Your Summer Rewind). When your Music book
                  account is deleted, this category of your data is deleted or
                  de-identified.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    • Data retained for extended time periods for limited
                    purposes
                  </strong>
                </p>
                <p>
                  After your account is deleted, we keep some data for a longer
                  time period but for very limited purposes. For example, we may
                  be subject to legal or contractual obligations that require
                  this. These may include mandatory data retention laws,
                  government orders to preserve data relevant to an
                  investigation, or data kept for the purposes of litigation. We
                  may also keep data that has been removed from the Music book
                  Service for a limited period of time. This could be:
                </p>
                <ul>
                  <li>- to help ensure users safety, or</li>
                  <li>- to help ensure users safety, or</li>
                </ul>
              </li>
            </ul>
            <p>
              This helps us investigate potential breaches of our User's
              Guidelines and Platform Rules. On the other hand, we will remove
              unlawful content if the law requires us to do so.
            </p>
          </div>
          {/* Inside 6  */}
        </div>
        {/* 7 Keeping your personal data safe  */}
        <div className="w-full mt-8 flex flex-col justify-start align-middle">
          <div className="flex flex-col px-32 justify-start align-middle">
            <h1 className="text-2xl font-semibold">
              8. Keeping your personal data safe
            </h1>
            <p className="text-xl">
              We're committed to protecting our user’s personal data. We put in
              place appropriate technical and organizational measures to help
              protect the security of your personal data. However, be aware that
              no system is ever completely secure.
            </p>
            <p className="text-xl">
              We have put various safeguards in place including
              pseudonymization, encryption such as Transport Layer Security
              (TLS) and AES, access, and retention policies to guard against
              unauthorized access and unnecessary retention of personal data in
              our systems. In addition, personal data stored in our computer
              systems are secured by physical security measures.
            </p>
            <p className="text-xl">
              To protect your user account, we encourage you to:
            </p>
            <ul>
              <li>
                • use a strong password that you only use for your Music book
                account
              </li>
              <li>• never share your password with anyone</li>
              <li>• limit access to your computer and browser</li>
              <li>
                • log out once you have finished using the Music book Service on
                a shared device
              </li>
              <li>• read more details on protecting your account</li>
            </ul>
            <p className="text-xl">
              You can log out of the Music book in multiple places at once by
              using the 'Sign out everywhere' function on your account page.
            </p>
            <p className="text-xl">
              If other individuals have access to your Music book account, then
              they can access personal data, controls, and the Music book
              Service available in your account. For example, you might have
              allowed someone to use your account on a shared device.
            </p>
            <p className="text-xl">
              It's your responsibility to only allow individuals to use your
              account where you're comfortable sharing this personal data with
              them. Anyone else's use of your Music book account may impact your
              personalized recommendations and be included in your data
              download.
            </p>
          </div>
          {/* Inside 7  */}
        </div>
        {/* 8. In App Purchase */}
        <div className="flex w-full flex-col justify-start items-start">
          <div className="flex flex-col px-32 justify-start align-middle">
            <h1 className="text-2xl font-semibold">8. In-App Purchase Terms</h1>
            <div className="">
              <p className="text-xl">
                Music Book (“we,” “our,” “us”) values the privacy and security
                of our users. This policy explains how we handle personal data,
                especially concerning in-app purchases, and outlines our
                commitment to secure payment processing.
              </p>
              <h4>Data Collection and Use</h4>
              <p className="text-xl">Personal Data</p>
              <p className="text-xl">
                We do not collect or store sensitive financial information such
                as UPI IDs, credit card numbers, or CVVs. All payments are
                securely processed through Cashfree, our payment service
                provider, in compliance with PCI-DSS standards. Cashfree manages
                the encryption and security of your payment details.
              </p>
              <p className="text-xl">Transactional History</p>
              <p className="text-xl">
                We retain a record of your transactions, including details like
                the date and amount of your purchase, for internal purposes,
                such as accounting, customer support, and to fulfill regulatory
                obligations. This information is stored securely and is never
                shared with third parties, except as required by law
              </p>
              <p className="text-xl">Payment Processing</p>
              <p className="text-xl">One-Time Payments</p>
              <p className="text-xl">
                Our app offers one-time payment options for ad placements:
              </p>
              <p className="text-xl font-semibold">3 Month Ad Slot: INR 599</p>
              <p className="text-xl font-semibold">
                6 Month Ad Slot: INR 1,199
              </p>
              <p className="text-xl font-semibold">
                12 Month Ad Slot: INR 2,399
              </p>
              <p className="text-xl">
                All transactions are processed securely via Cashfree. By making
                a payment, you agree to our terms and conditions and understand
                that all purchases are non-refundable.
              </p>
              <p className="text-xl">Security Measures</p>
              <p className="text-xl">
                We implement robust security measures to safeguard your data.
                Our partnership with Cashfree ensures encrypted transactions,
                and no financial details are stored on our servers.
              </p>
              <p className="text-xl">User Rights and Data Access</p>
              <p className="text-xl">
                You have the right to request access to your transactional
                history or request its deletion, subject to legal and business
                obligations. We are committed to protecting your privacy in
                accordance with applicable laws.
              </p>
              <p className="text-xl">Issue Resolution</p>
              <p className="text-xl">
                If you experience any payment-related issues, our support team
                is ready to assist. Please contact us at
                support@musicbook.co.in. We aim to address your concerns
                promptly and ensure a seamless experience.
              </p>
              <p className="text-xl">Compliance and Updates</p>
              <p className="text-xl">
                We comply with the guidelines set by Google Play Store and Apple
                App Store regarding data privacy, payment processing, and user
                rights. This policy is subject to change, and updates will be
                posted on our app and website. We encourage you to review it
                periodically.
              </p>
              <p className="text-xl">Contact Information</p>
              <p className="text-xl">
                For any questions or concerns about this policy or data handling
                practices, please reach out to: Support Email:
                support@musicbook.co.in{" "}
              </p>
            </div>
          </div>
        </div>
        {/* 9   Children  */}
        <div className="w-full mt-8 flex flex-col justify-start align-middle">
          <div className="flex flex-col px-32 justify-start align-middle">
            <h1 className="text-2xl font-semibold">9. Children</h1>
            <p className="text-xl">
              Note: This Policy doesn't apply to Music book Kids unless the
              Music book Kids Privacy Policy says so. Music book Kids is a
              separate Music book application.
            </p>
            <p className="text-xl">
              The Music book Service has a minimum 'Age Limit' in each country
              or region. The Music book Service is not directed to children
              whose ages:
            </p>
            <p className="text-xl">is under the age of 18 years</p>
            <p className="text-xl">
              makes it illegal to process their personal data, or
            </p>
            <p className="text-xl">
              requires parental consent to process their personal data
            </p>
            <p className="text-xl">
              We do not knowingly collect or use personal data from children
              under the applicable Age Limit. If you're under the Age Limit, do
              not use the Music book Service, and do not provide any personal
              data to us. Instead, we recommend using a Music book Kids account.
            </p>
            <p className="text-xl">
              If you're a parent of a child under the Age Limit and become aware
              that your child has provided personal data to Music book, contact
              us.
            </p>
            <p className="text-xl">
              If we learn that we've collected the personal data of a child
              under the applicable Age Limit, we'll take reasonable steps to
              delete the personal data. This may require us to delete the Music
              book account for that child.
            </p>
            <p className="text-xl">
              When using a shared device on the main Music book Service, be
              cautious about playing or recommending any inappropriate content
              to individuals under 18 years old.
            </p>
          </div>
          {/* Inside 8  */}
        </div>
        {/* 9   Changing this policy  */}
        <div className="w-full mt-8 flex flex-col justify-start align-middle">
          <div className="flex flex-col px-32 justify-start align-middle">
            <h1 className="text-2xl font-semibold">10. Changing this policy</h1>
            <p className="text-xl">
              We may occasionally make changes to this Policy.
            </p>
            <p className="text-xl">
              When we make material changes to this Policy, we'll provide you
              with prominent notice as appropriate under the circumstances. For
              example, we may display a prominent notice within the Music book
              Service or send you an email or device notification.
            </p>
          </div>
          {/* Inside 9  */}
        </div>
        {/* 10   How to contact us  */}
        <div className="w-full mt-8 flex flex-col justify-start align-middle">
          <div className="flex flex-col px-32 justify-start align-middle">
            <h1 className="text-2xl font-semibold">10. How to contact us</h1>
            <p className="text-xl">
              For any questions or concerns about this Policy, contact our
              Grievance Officer/Data Protection Officer any one of these ways:
            </p>
            <p>
              <strong>Email:</strong>support@musicbook.co.in
            </p>
            <p>
              For the GDPR, Music book is the data controller of personal data
              processed under this Policy.
            </p>
            <p>
              <strong>@ Music book</strong>
            </p>
          </div>
          {/* Inside 10  */}
        </div>
        {/* Container  */}
      </div>
    </div>
  );
}
export default PrivacyPage;
