import React, { useState } from "react";

const LanguageSelection = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("");

  //   "Kannada",
  //   "English",
  //   "Hindi",
  //   "Bengali",
  //   "Marathi",
  //   "Telugu",
  //   "Tamil",
  //   "Gujarati",
  //   "Urdu",
  //   "Bhojpuri",
  //   "Malayalam",
  //   'Odia',
  //   "Punjabi",
  //   "Rajasthani",
  //   "Chhattisgarhi",
  //   "Assamese",
  //   "Maithili",
  //   "Magahi",
  //   "Haryanvi",
  //   "Khortha/Khotta",
  //   "Marwari",
  //   "Santali",
  //   "Kashmiri",
  //   "Bundeli/Bundel khandi",
  //   "Malvi",
  //   "Sadan/Sadri",
  //   "Mewari",
  //   "Awadhi",
  //   "Wagdi",
  //   "Lamani/Lambadi",
  //   "Pahari[note 4]",
  //   "Bhili/Bhilodi",
  //   "Hara/Harauti",
  //   "Nepali",
  //   "Gondi",
  //   "Bagheli/Baghel Khandi",
  //   "Sambalpuri",
  //   "Dogri",
  //   "Garhwali",
  //   "Nimadi",
  //   "Surjapuri",
  //   "Konkani",
  //   "Kumauni",
  //   "Kurukh/Oraon",
  //   "Tulu",
  //   "Manipuri",
  //   "Surgujia",
  //   "Sindhi",
  //   "Bagri",
  //   "Ahirani",
  //   "Banjari",
  //   "Brajbhasha",
  //   "Dhundhari",
  //   "Bodo/Boro",
  //   "Ho",
  //   "Gojri/Gujjari/Gujar",
  //   "Mundari",
  //   "Garo",
  //   "Kangri",
  //   "Khasi",
  //   "Kachchhi",

  const languages = [
    { id: 1, name: "Kannada" },
    { id: 2, name: "English" },
    { id: 3, name: "Hindi" },
    { id: 4, name: "Bengali" },
    { id: 5, name: "Marathi" },
    { id: 6, name: "Telugu" },
    { id: 7, name: "Tamil" },
    { id: 8, name: "Gujarati" },
    { id: 9, name: "Urdu" },
    { id: 10, name: "Bhojpuri" },
    { id: 11, name: "Malayalam" },
    { id: 12, name: "Odia" },
    { id: 13, name: "Punjabi" },
    { id: 14, name: "Rajasthani" },
    { id: 15, name: "Chhattisgarhi" },
    { id: 16, name: "Assamese" },
    { id: 17, name: "Maithili" },
    { id: 18, name: "Magahi" },
    { id: 19, name: "Haryanvi" },
    { id: 20, name: "Khortha/Khotta" },
    { id: 21, name: "Marwari" },
    { id: 22, name: "Santali" },
    { id: 23, name: "Kashmiri" },
    { id: 24, name: "Bundeli/Bundel Khandi" },
    { id: 25, name: "Malvi" },
    { id: 26, name: "Sadan/Sadri" },
    { id: 27, name: "Mewari" },
    { id: 28, name: "Awadhi" },
    { id: 29, name: "Wagdi" },
    { id: 30, name: "Lamani/Lambadi" },
    { id: 31, name: "Pahari" },
    { id: 32, name: "Bhili/Bhilodi" },
    { id: 33, name: "Hara/Harauti" },
    { id: 34, name: "Nepali" },
    { id: 35, name: "Gondi" },
    { id: 36, name: "Bagheli/Baghel Khandi" },
    { id: 37, name: "Sambalpuri" },
    { id: 38, name: "Dogri" },
    { id: 39, name: "Garhwali" },
    { id: 40, name: "Nimadi" },
    { id: 41, name: "Surjapuri" },
    { id: 42, name: "Konkani" },
    { id: 43, name: "Kumauni" },
    { id: 44, name: "Kurukh/Oraon" },
    { id: 45, name: "Tulu" },
    { id: 46, name: "Manipuri" },
    { id: 47, name: "Surgujia" },
    { id: 48, name: "Sindhi" },
    { id: 49, name: "Bagri" },
    { id: 50, name: "Ahirani" },
    { id: 51, name: "Banjari" },
    { id: 52, name: "Brajbhasha" },
    { id: 53, name: "Dhundhari" },
    { id: 54, name: "Bodo/Boro" },
    { id: 55, name: "Ho" },
    { id: 56, name: "Gojri/Gujjari/Gujar" },
    { id: 57, name: "Mundari" },
    { id: 58, name: "Garo" },
    { id: 59, name: "Kangri" },
    { id: 60, name: "Khasi" },
    { id: 61, name: "Kachchhi" },
  ];

  const handleContinue = () => {
    if (selectedLanguage) {
      alert(`You selected: ${selectedLanguage}`);
      // Navigate or perform actions based on the selected language
    } else {
      alert("Please select a language to continue.");
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-black">
      <div className="w-11/12 max-w-md bg-[#232323] shadow-lg rounded-lg p-6">
        <h1 className="text-xl font-bold text-white-800 text-center ">
          Welcome to Music book
        </h1>
        <p className="text-center text-gray-300">
          {!selectedLanguage
            ? "Please select your mother tongue to continue"
            : `You have selected: ${selectedLanguage} `}
        </p>
        <div className="relative mb-6">
          <select
            value={selectedLanguage}
            onChange={(e) => setSelectedLanguage(e.target.value)}
            className="block w-full px-4 py-3 border rounded-lg shadow-sm focus:ring-2 focus:ring-purple-500 focus:outline-none bg-black text-white border-gray-700"
          >
            <option value="" disabled>
              Choose a language
            </option>
            {languages.map((lang) => (
              <option key={lang.id} value={lang.name}>
                {lang.name}
              </option>
            ))}
          </select>
        </div>
        <button
          onClick={handleContinue}
          className={`w-full bg-[#471CF1] text-white py-3 rounded-lg shadow-md hover:bg-[#471CF1] transition duration-300 ${
            selectedLanguage ? "" : "opacity-50 cursor-not-allowed"
          }`}
          disabled={!selectedLanguage}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default LanguageSelection;
