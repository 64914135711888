import {
  AspectRatio,
  Box,
  Button,
  ButtonGroup,
  Chip,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/joy";
import React, { useEffect, useState } from "react";
import BigPlayButton from "./BigPlayButton";
import { motion } from "framer-motion";
import ReactPlayer from "react-player/lazy";
import { useNavigate } from "react-router-dom";
import VideoThumbnail from "react-video-thumbnail";
import moment from "moment";

function VideoListCard({
  data,
  shimmer = false,
  disabledClick = false,
  action,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  return (
    <Grid xs={12} sm={6} md={4} lg={3}>
      <Skeleton loading={shimmer} variant="inline">
        <AspectRatio
          onClick={() => {
            disabledClick && action && action();
            !disabledClick && navigate(`/vodcast/player/${data?._id}`);
          }}
          onMouseOver={() => {
            setIsHovered(true);
          }}
          onMouseOut={() => {
            setIsHovered(false);
          }}
          sx={{
            width: "100%",
            position: "relative",
          }}
        >
          <Box sx={{ position: "relative" }}>
            <motion.div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
              }}
              animate={isHovered ? "hide" : "show"}
              variants={{
                show: { opacity: 1 },
                hide: { opacity: 0 },
              }}
            >
              {data?.media?.[0]?.thumbnail ? (
                <img
                  src={data?.media?.[0]?.thumbnail}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              ) : (
                <VideoThumbnail
                  cors={true}
                  videoUrl={data?.media?.[0]?.file}
                  // thumbnailHandler={(thumbnail) => console.log(thumbnail)}
                />
              )}
            </motion.div>
            <motion.div
              style={{
                display: "flex",
                position: "absolute",
                width: "100%",
                height: "100%",
              }}
              animate={isHovered ? "show" : "hide"}
              variants={{
                show: { opacity: 1 },
                hide: { opacity: 0 },
              }}
            >
              <ReactPlayer
                muted={true}
                width="100%"
                height="100%"
                playing={isHovered}
                url={data?.media?.[0]?.file}
                style={{ width: "100%", height: "100%" }}
              />
            </motion.div>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                position: "absolute",
                zIndex: 0,
                background: "#00000057",
                // background:
                //   "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.90) 100%)",
              }}
            />
            <motion.div
              style={{ display: "flex", margin: 0 }}
              animate={isHovered ? "change" : "normal"}
              variants={{
                normal: { opacity: 0.8, scale: 1 },
                change: { opacity: 1, scale: 1.2 },
              }}
            >
              <BigPlayButton />
            </motion.div>
          </Box>
        </AspectRatio>
      </Skeleton>
      <Box sx={{ pb: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            pt: 0.7,
            pb: 0.3,
          }}
        >
          <Skeleton loading={shimmer} variant="inline">
            <Typography level="body-xs" sx={{ color: "#FFFFFF99" }}>
              {data?.views_count || 0} Views
            </Typography>
          </Skeleton>
          <Skeleton loading={shimmer} variant="inline">
            <Typography level="body-xs" sx={{ color: "#FFFFFF3D" }}>
              {moment(data?.createdAt).fromNow()}
            </Typography>
          </Skeleton>
        </Box>
        {shimmer ? (
          <Skeleton loading={shimmer} variant="text" sx={{ width: "70%" }} />
        ) : (
          <Typography
            level="title-sm"
            sx={{
              px: 0,
              pb: 0.7,
              color: "#fff",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {data?.title}
          </Typography>
        )}

        {!shimmer && (
          <Stack direction="row" spacing={1} sx={{ px: 0, pb: 0 }}>
            {data?.tags?.map((item, index) => (
              <Button
                size="sm"
                sx={{
                  paddingX: 1.4,
                  paddingY: 0.5,
                  minHeight: 0,
                  fontSize: "70%",
                  borderRadius: "100px",
                  backgroundColor: "#292929",
                  // backgroundColor: "#fff",
                  color: "#ececec",
                }}
                key={index}
              >
                #{item}
              </Button>
            ))}
          </Stack>
        )}
      </Box>
    </Grid>
  );
}

export default VideoListCard;
