import React from "react";

const LanguageDropDown = ({ options, placeholder, onChange, value }) => {
  return (
    <div className="relative">
      <select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="block w-full px-1 mt-2 py-2 border rounded-md text-sm shadow-sm focus:ring-2 focus:to-blue-300 focus:outline-none bg-[#242424] text-[#ffffff80] border-gray-700"
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options.map((option) => (
          <option key={option.id} value={option.name}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LanguageDropDown;
