import { Avatar, Box, Skeleton, Stack, Typography } from "@mui/joy";
import React from "react";
import ActionButton from "./ActionButton";
import "../style/alert.module.css";

// import { followFunction, setIsFollow } from "../../Redux/slices/TempSlice";
import { followFunction } from "../../Redux/slices/TempSlice";

import constants from "../../configs/constants";
import { FaRegStar, FaStar, FaStarHalfAlt } from "react-icons/fa";
import swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";

function ProfileCard({
  data,
  profileRating,
  followState,
  setFollowState,
  loading,
  postRating,
}) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const isOwn = localStorage.getItem("user_id") === data?._id;

  return (
    <Stack direction="row" sx={{ my: 2, alignItems: "center", gap: 1 }}>
      <Box
        sx={{
          width: { xs: 60 },
          aspectRatio: 1,
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="PostProfileImageContainer"
          style={{
            display: "none",
            position: "absolute",
            zIndex: 0,
            transform: "scale(1.1)",
          }}
        >
          <div
            className="circ"
            style={{
              display: "none",
            }}
          >
            <div className="sect"></div>
            <div
              className="sect"
              style={{
                borderRightColor:
                  Math.round(profileRating) >= 4 && "rgba(0, 0, 0, 0)",
              }}
            ></div>
            <div className="sect"></div>
            <div
              className="sect"
              style={{
                borderRightColor:
                  Math.round(profileRating) >= 3 && "rgba(0, 0, 0, 0)",
              }}
            ></div>
            <div className="sect"></div>
            <div
              className="sect"
              style={{
                borderRightColor:
                  Math.round(profileRating) >= 2 && "rgba(0, 0, 0, 0)",
              }}
            ></div>
            <div className="sect"></div>
            <div
              className="sect"
              style={{
                borderRightColor:
                  Math.round(profileRating) >= 1 && "rgba(0, 0, 0, 0)",
              }}
            ></div>
            <div className="sect"></div>
            <div
              className="sect"
              style={{
                borderRightColor:
                  Math.round(profileRating) === 5 && "rgba(0, 0, 0, 0)",
              }}
            ></div>
          </div>
        </div>
        <Skeleton
          variant="circular"
          loading={loading}
          sx={{ borderRadius: "50%" }}
        >
          <Avatar
            src={data?.pic}
            size="lg"
            slotProps={{
              img: { style: { transform: "scale(0.9)", borderRadius: "50%" } },
              root: {
                style: { background: constants.colors.secondary },
              },
            }}
          />
        </Skeleton>
      </Box>
      <Stack>
        <Skeleton variant="rectangular" loading={loading}>
          <Typography
            fontSize={{ xs: 14, sm: 16, md: 18, lg: 20 }}
            lineHeight={"100%"}
          >
            {data?.full_name}
          </Typography>
        </Skeleton>
        {/* <Typography fontSize={{ xs: 10, sm: 10, md: 12, lg: 12 }}>
          @{data?.name}
        </Typography> */}
        <Skeleton variant="rectangular" loading={loading}>
          <Box sx={{ display: "flex", mt: 0.3 }}>
            {Array.from({ length: 5 }, (_, index) => {
              let starHalf = index + 0.5;
              return profileRating >= index + 1 ? (
                <FaStar key={index} color={"#FFA800"} />
              ) : profileRating >= starHalf ? (
                <FaStarHalfAlt key={index} color={"#FFA800"} />
              ) : (
                <FaRegStar key={index} color={"#FFA800"} />
              );
            })}
          </Box>
        </Skeleton>
      </Stack>
      {!isOwn && (
        <Skeleton variant="rectangular" loading={loading} sx={{ width: 100 }}>
          <ActionButton
            title={followState ? "Following" : "Follow"}
            onClick={() => {
              if (followState) {
                swal
                  .fire({
                    iconColor: "#fff",
                    iconHtml: '<i class="ri-user-unfollow-line"></i>',
                    titleText: "Unfollow User",

                    text: "Are you sure you want to Unfollow this user?",
                    color: "#fff",
                    confirmButtonText: "Unfollow",
                    confirmButtonColor: "red",
                    showCancelButton: true,
                    cancelButtonText: "Discard",
                    reverseButtons: true,
                    background: "#1f077dfc",
                    // background: "136deg, #471CF1 8.39%, rgba(31, 7, 125, 0.99) 93.1%1",
                    dangerMode: true,
                  })
                  .then((status) => {
                    if (status.isConfirmed) {
                      dispatch(followFunction({ user: user, profile: data }));
                      setFollowState(false);
                    }
                  });
              } else {
                dispatch(followFunction({ user: user, profile: data }));
                setFollowState(true);
              }
            }}
            icon={{ type: "ionicon", name: "" }}
            sx={
              followState
                ? {
                    ml: 4,
                    minHeight: 35,
                    backgroundColor: constants.colors.disabled,
                  }
                : { ml: 4, minHeight: 35 }
            }
          />
        </Skeleton>
      )}
    </Stack>
  );
}

export default ProfileCard;
